<template>
  <div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <div class="row">
            <TopBar
              v-model:selectAll="selectAll"
              v-model:line-options="lineOptions"
              v-model:rows="rows"
              v-model:items="items"
              v-model:selectedLineOption="selectedLineOption"
              v-model:selectedItems="selectedItems"
              v-on:addNewRow="$refs.addModal.showModal()"
              v-on:delete="deleteOperations"
              path="production::operations"
              :methods="listOptions"
              :show-languages="false"
              :show-currencies="false"
            />
            <DynamicTable
              v-model:rows="rows"
              v-model:items="items"
              v-model:select-all="selectAll"
              v-on:update:val="valUpdated"
              :show-pin="false"
              v-on:selected="(e) => (selectedItems = e)"
              :type="this.typePular"
              v-on:loadMore="loadMore"
              v-on:order="orderBy"
              :order="order"
              :fix-height="true"
              :key="tableKey"
            />
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal
      ref="multipleEditModal"
      :title="`Edit ${selectedItems.length} operations`"
      :text="`<p>You will edit<b> ${selectedItems.length}</b> operations</p>
 <p>You will edit all selected rows.</p>`"
      cancel-button="Modify only edited row"
      :ok-button="`Edit selected ${selectedItems.length} rows`"
      v-on:success="multipleEdit"
    />
    <NewManufacturingOperation ref="addModal" v-on:add="addNewRow" />
  </div>
</template>

<script>
import DynamicTable from "@/components/lists/DynamicTable";
import TopBar from "@/components/lists/TopBar";
import { useStore } from "vuex";
import ws from "@/modules/ws";
import http from "@/modules/http";
import lineOptions from "@/modules/lineOptions";
import NewManufacturingOperation from "../../components/modals/NewManufacturingOperation";
import ConfirmModal from "../../components/modals/ConfirmModal";

export default {
  name: "Operations",
  components: { ConfirmModal, NewManufacturingOperation, DynamicTable, TopBar },
  created() {},
  data() {
    return {
      store: useStore(),
      tableKey: 0,
      rows: [],
      filtersTimeOut: null,
      selectedLineOption: 0,
      lineOptions: [],
      selectAll: false,
      items: [],
      selectedItems: [],
      selectedEditMethod: {},
      page: 1,
      newCustomRowValue: undefined,
      listOptions: [],
      order: {
        row: null,
        desc: true,
      },
    };
  },
  computed: {
    warehouses() {
      return this.store.state.warehouses;
    },
    path() {
      return this.$route.path;
    },
    sessionId() {
      return this.store.state.sessionId;
    },
    filters() {
      return this.store.state.filters?.operations;
    },
  },
  mounted() {
    this.$store.commit("setFilterPage", "operations");
    this.loadData();

    ws.init();

    ws.subscribe("add", "operation", (e) => {
      if (e.session !== this.sessionId) {
        this.items.push(e.object);
        this.tableKey++;
      }
    });

    ws.subscribe("modify", "operation", (e) => {
      let obj = this.items[this.items.findIndex((o) => o.id === e.objectId)];
      if (obj !== undefined) {
        if (e.session !== this.sessionId) {
          obj[e.rowKey] = e.value;
        }
      }
    });
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.items = undefined;
          this.page = 1;
          this.loadData();
        }, 300);
      },
    },
  },
  methods: {
    orderBy(order) {
      this.items = undefined;
      this.page = 1;
      this.order = order;
      this.loadData();
    },
    loadMore() {
      this.page++;
      this.loadData();
    },
    addNewRow(row) {
      row.selected = this.selectAll;
      if (this.selectAll) {
        this.selectAllDiff++;
      }
      this.items.unshift(row);
      this.tableKey++;
    },
    loadData() {
      let url = `/manufacturing/operations?page=${this.page}&order[desc]=${
        this.order.desc ? 1 : 0
      }&order[row]=${this.order.row ? this.order.row : ""}`;

      if (Object.keys(this.filters).length > 0) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            for (const value of this.filters[filter].value) {
              url += `&filter[${filter}][]=${value}`;
            }
          } else {
            url += `&filter[${filter}]=${this.filters[filter].value}`;
          }
        }
      }
      http.fetch(url).then((data) => {
        if (this.page === 1) {
          if (this.rows.length === 0) {
            let cs = data.rows.find(
              (e) => e.row_key === "manufacturing_operation_category_id"
            );
            cs.values = data.categories;
            cs.createOption = (e) => {
              for (const obj of cs.values) {
                if (obj.name.toLowerCase() === e.toLowerCase()) {
                  return obj;
                }
              }
              this.newCustomRowValue = e;
              return { id: 0, name: e };
            };

            this.rows = lineOptions.init(data.rows, data.line_options);
            this.lineOptions = data.line_options;
            this.selectedLineOption = data.line_options[0]?.id;
          }
          this.items = data.data;
          this.tableKey++;
        } else {
          for (const row of data.data) {
            this.items.push(row);
          }
        }
      });
    },
    valUpdated(val, row, id, item) {
      if (this.selectedItems.length > 1) {
        this.$refs.multipleEditModal.showModal();
        this.selectedEditMethod = {
          val: val,
          row: row,
          id: id,
          item: item,
        };
      }
      this.editItems(val, row, id, item);
    },
    multipleEdit() {
      this.editItems(
        this.selectedEditMethod.val,
        this.selectedEditMethod.row,
        this.selectedItems.map((e) => e.id),
        this.selectedEditMethod.item
      );
    },
    editItems(val, row, id, item) {
      let data = { id: id };
      data[row] = val;

      if (typeof val === "object" && val.length === 0) {
        val.push({ id: 0 });
      }

      if (val === 0 && this.newCustomRowValue === undefined) {
        return;
      }

      if (val === 0 && this.newCustomRowValue !== undefined) {
        data[row] = this.newCustomRowValue;
        data.add = 1;
        this.newCustomRowValue = undefined;
      }

      if (row === "cost") {
        data["cost"] = val.amount;
        data["cost_currency_code"] = val.currency;
      }

      http
        .fetch(`/manufacturing/operations`, data, true, "PUT")
        .then((respond) => {
          if (data.add) {
            let rowData = this.rows.filter((e) => e.key === row)[0];
            rowData.values.push({ id: respond.id, name: data[row] });
            item[row] = respond.id;
            this.newCustomRowValue = undefined;
          }
        });
    },
    deleteOperations() {
      let data = [];
      for (const item of this.selectedItems) {
        data.push(item.id);
      }
      http
        .fetch(`/manufacturing/operations`, { rows: data }, true, "DELETE")
        .then(() => {
          for (const item of this.selectedItems) {
            let i = this.items.indexOf(item);
            if (i > -1) {
              this.items.splice(i, 1);
            }
          }
          this.tableKey++;
        });
    },
  },
};
</script>
