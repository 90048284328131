import { defineAsyncComponent, markRaw } from "vue";

const lineOptions = {
  setComponent(rows, key, component) {
    rows[key].component = markRaw(
      defineAsyncComponent(() =>
        import(`../components/lists/listItems/${component}.vue`)
      )
    );
  },

  init(rows, lineOptions) {
    for (const key in rows) {
      rows[key].name = rows[key].row_name;
      rows[key].key = rows[key].row_key;
      if (typeof rows[key].component === "string") {
        let c = rows[key].component;
        delete rows[key].component;
        this.setComponent(rows, key, c);
      } else {
        delete rows[key].component;
      }
      if (typeof rows[key].enums === "string") {
        rows[key].values = JSON.parse(rows[key].enums);
      }
      delete rows[key].enums;
      if (typeof rows[key].just_in_type === "string") {
        rows[key].justInType = JSON.parse(rows[key].just_in_type);
      }
      delete rows[key].just_in_type;

      if (rows[key].could_hidden === false) {
        rows[key].show = true;
      } else {
        rows[key].show =
          lineOptions[0]?.rows.indexOf(rows[key].row_key) > -1;
      }
    }

    return rows;
  },
};

export default lineOptions;
